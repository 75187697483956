<template>
  <div class="toasts">
    <div v-for="toast in getToasts" class="toast align-items-center text-white border-0 show" :class="toast.class" role="alert" aria-live="assertive"
         aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{ toast.message }}
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  name: 'BootstrapToast',
  computed: {
    ...mapGetters(['getToasts', 'getApiUrl'])
  },

  watch: {
    '$store.state.toasts': {
      deep: true,
      handler() {
        let Vueinner = this;
        setTimeout(() => {
          Vueinner.$store.commit('removeToast');

        }, 7500)
      },
    }
  }
}
</script>
<style lang="scss">
.toasts {
  position: fixed;
  bottom: 5px;
  right: 5px;
  .toast {
    margin-bottom: 5px;
  }
}
</style>
