const dev = {
  clientId: 'ak-bewerbungsmanagement',
  OPServer: 'https://oidc.intg-dfb-akademie.de/auth/realms/AK',
  redirectURL: 'https://client-dev.dfb-trainerbewerbung.de/auth/siginwin/main',
  logoutUrlComplete: 'https://oidc.intg-dfb-akademie.de/auth/realms/AK/protocol/openid-connect/logout?redirect_uri=https%3A%2F%2Fclient-dev.dfb-trainerbewerbung.de%2Fauth%2Fsiginwin%2Fmain',
  scope: 'openid email profile',
  userInfoEndpoint: '/protocol/openid-connect/userinfo',
  extra: {prompt: 'consent', access_type: 'offline'},
  apiUrl: 'https://api.dfb-trainerbewerbung.de/',
  version: '1.9.5'
};


const int = {
  clientId: 'ak-bewerbungsmanagement',
  OPServer: 'https://oidc.intg-dfb-akademie.de/auth/realms/AK',
  redirectURL: 'http://localhost:8080/auth/siginwin/main',
  logoutUrlComplete: 'https://oidc.intg-dfb-akademie.de/auth/realms/AK/protocol/openid-connect/logout?redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2Fsiginwin%2Fmain',
   scope: 'openid email profile',
  userInfoEndpoint: '/protocol/openid-connect/userinfo',
  extra: {prompt: 'consent', access_type: 'offline'},
  apiUrl: 'https://api.dfb-trainerbewerbung.de/',
  version: '1.9.0'

};

const prod = {
  clientId: 'ak-bewerbungsmanagement',
  OPServer: 'https://oidc.dfb.de/auth/realms/AK',
  redirectURL: 'https://dfb-trainerbewerbung.de/auth/siginwin/main',
  logoutUrlComplete: 'https://oidc.dfb.de/auth/realms/AK/protocol/openid-connect/logout?redirect_uri=https%3A%2F%2Fdfb-trainerbewerbung.de%2Fauth%2Fsiginwin%2Fmain',
  scope: 'openid account_by_dfbnet_id',
  userInfoEndpoint: '/protocol/openid-connect/userinfo',
  extra: {prompt: 'consent', access_type: 'offline'},
  apiUrl: 'https://api.dfb-trainerbewerbung.de/',
  version: '1.10.7'

};

let config  = int;

if (process.env.NODE_ENV === 'production') {
  config = prod;
}



export default {
  // Add common config values here
  ...config
};
