<template>

  <MainHeader headline="Login" text="Sie werden weitergeleitet..." />

</template>

<script>
import MainHeader from "@/components/MainHeader";

import {
  AuthorizationServiceConfiguration,
  AuthorizationRequest, RedirectRequestHandler,
  FetchRequestor, LocalStorageBackend, DefaultCrypto
} from '@openid/appauth';
import { NoHashQueryStringUtils } from '@/auth/noHashQueryStringUtils';
import environment from '../auth/environment';
export default {
  name: 'LoginView',


  components: {
MainHeader
  },
  created() {

    this.login();
  },

  methods: {

    login() {
      const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
      AuthorizationServiceConfiguration.fetchFromIssuer(environment.OPServer, new FetchRequestor())
        .then((response) => {
          const authRequest = new AuthorizationRequest({
            client_id: environment.clientId,
            redirect_uri: environment.redirectURL,
            scope: environment.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            // extras: environment.extra
          });
          authorizationHandler.performAuthorizationRequest(response, authRequest);
        })
        .catch(error => {

        });
    }

  }

}
</script>
