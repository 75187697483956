<template>

  <MainHeader headline='Impressum' text=''/>
  <div class="container">
    <div class="dfb-Rte"><p><br> Das Portal www.dfb-akademie.de mit seinen Subsites und mobilen Applikationen ist ein
      Angebot des Deutschen Fußball-Bundes.</p>
      <p><strong>Anschrift:</strong></p>
      <p><strong>DFB GmbH &amp; Co. KG</strong><br> DFB-Campus<br> Kennedyallee 274<br> 60528&nbsp;Frankfurt am Main</p>
      <p>Telefon: +49 69 67 88-0<br> Fax: +49 69 67 88-266<br> <strong>E-Mail:</strong> <a href="mailto:info@dfb.de">info@dfb.de</a>
      </p>
      <p>Registergericht: Amtsgericht Frankfurt/Main<br> Registernummer: HRA 52430<br> Sitz der Gesellschaft:
        Frankfurt/Main<br> Vorsitzender des Aufsichtsrats: Alexander Wehrle</p>
      <p><strong>Persönlich haftende Gesellschafterin der DFB GmbH &amp; Co. KG:</strong><br>
        DFB-Verwaltungsgesellschaft mbH<br> Kennedyallee 274<br> 60528&nbsp;Frankfurt am Main</p>
      <p><strong>Geschäftsführer der DFB-Verwaltungsgesellschaft mbH:</strong><br> Oliver
        Bierhoff<br> Dr. Holger Blask<br> Manuel Hartmann<br> &nbsp;<br> Registergericht: Amtsgericht Frankfurt/Main<br>
        Registernummer: HRB 125153</p>
      <p>Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE 114232696</p>
      <p>Die Datenverarbeitung findet teilweise in gemeinsamer Verantwortlichkeit mit dem DFB e.V. statt. Weiterführende
        Informationen finden Sie in unseren <a href="https://www.dfb.de/datenschutzerklaerung/" target="_blank">Datenschutzhinweisen</a>.
      </p>
      <p><strong>Verantwortlichkeit für redaktionelle Inhalte:</strong></p>
      <p>Gesamtverantwortung: Tobias Haupt<br> Redaktionsleitung: Ralf Koch<br> Redaktionelle Koordination: Norbert
        Vieth, Tobias Bach, Jan Fedra</p>
      <p>DFB GmbH &amp; Co. KG<br> DFB-Campus<br> Kennedyallee 274<br> 60528&nbsp;Frankfurt am Main</p>
      <p><strong>Zuständige Aufsichtsbehörde für audiovisuelle Medien:</strong></p>
      <p>Hessische Landesanstalt für privaten Rundfunk und neue Medien (LPR Hessen)<br> Sitzland Deutschland</p>
      <p><strong>Online-Streitbeilegung</strong></p>
      <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit, die Sie unter
        https://ec.europa.eu/consumers/odr finden. Die DFB GmbH &amp; Co. KG ist nicht verpflichtet, an
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen und nimmt daher auch nicht
        teil.</p></div>

  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader'

export default {
  name: 'Imprint',
  components: {

    MainHeader,
  },


  created() {

  },
  methods: {
    showNav: function (index) {
      this.nav = index
    },

  }
}
</script>
