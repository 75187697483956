<template>
  <div class="entryComments" v-if="$store.state.currentApplication.request_state === 'readable'">
    <span :title="$t('comment.deleted')" @click="showComment('deleted', entry.comment)" v-if="entry.liga === '-- Gelöscht --'" class="delEntry btn btn-small btn-success">?</span>
    <span :title="$t('comment.edited')" @click="showComment('edited', entry.comment)" v-else-if="entry.oldSubmit" class="delEntry btn btn-small btn-success" >?</span>
  </div>
</template>
<script>
export default {
  name: 'EntryComment',
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  methods: {
    showComment(type, comment) {
      type = this.$t('comment.'+type);
      this.$alert(comment, type, 'info',{
        confirmButtonText: 'OK',
        dangerouslyUseHTMLString: true,
      });
    },
  }
}

</script>
<style>
.swal2-animate-info-icon {
  display: none !important;
}
.swal2-header {
  margin-bottom: 14px;
}

div#swal2-content {
  font-size: 14px;
}
h2.swal2-title {
  font-size: 22px;
}

button.swal2-confirm.swal2-styled {
  border-left-color: #21AE80 !important;
  border-right-color: #21AE80 !important;
  background: #21AE80;
}
</style>
