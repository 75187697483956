<template>

  <UserRegister />

</template>

<script>

import UserRegister from '@/components/UserRegister'

export default {
  name: 'RegisterView',

  components: {
    UserRegister
  }

}
</script>
