<template>
  <div class="taskLineWrap  w-100">
    <div class="d-flex  w-100">

      <div class="lineitem l-title" @click="setTask(task, 'details')"> <span style="width: 100px;">#{{ task.hash }}</span>
        {{ task.title }}
      </div>
      <div class="lineitem l-attachments" @click="setTask(task, 'attachments')">
        <template v-if="task._countAttachments > 0">
          <i class="fa-regular fa-paperclip"></i><span> <span class="incNumber">{{ task._countAttachments }}</span></span>
        </template>
      </div>
      <div class="lineitem l-subtasks" @click="setTask(task, 'subtasks')" >

        <template v-if="task._countIssues > 0">
          <i class="fa-regular fa-list-tree"></i><span> <span class="incNumber">{{ task._countIssues }}</span></span>
        </template>
      </div>

      <div class="lineitem l-prio"><span :tooltip="task.issuePriorityTitle" :position="'left'"
                                         :style="'color:'+task.issuePriorityColor"><i class="fa-regular"
                                                                                      :class="task.issuePriorityIcon"></i></span>
      </div>
      <div class="lineitem l-avatar">
<!--        <img style="max-width: 40px"-->
<!--                                          :src="$filters.employeeIcon(getEmployees, task.employee_id, 'id')"-->
<!--                                          class="sketchAvatar">-->
      </div>
      <div class="lineitem l-state"><span :tooltip="task.issueStateTitle" :position="'left'"
                                          :style="'color:'+task.issueStateColor"><i class="fa-regular"
                                                                                    :class="task.issueStateIcon"></i></span>
      </div>

      <div class="lineitem l-hours">
        <template v-if="task.estimated_hours && task.estimated_hours > 0">
          <i class="fa-regular fa-hourglass-end"></i> {{ task.estimated_hours }}
        </template>
      </div>
      <div class="lineitem l-date duedate"
           :class="(task.due_date < today) ? 'due' : (task.due_date === today) ? 'today' : ''"
           :tooltip="'Abgabedatum'" :position="'left'">{{ $filters.formatDate(task.due_date) }}
      </div>
      <div class="lineitem l-actions ml-auto last">
        <span @click="setTask(task)"><i class="fa-regular fa-edit"></i>
        </span>
      </div>
    </div>
    <!--    <div class="lineitem last ml-auto">-->
    <!--      <template v-if="taskActive && taskActive.id === task.id">-->
    <!--        <i class="fa-regular fa-x" @click="$store.commit('closeTask')"></i>-->
    <!--      </template>-->
    <!--      <template v-else><i class="fa-regular fa-chevrons-up"></i></template>-->
    <!--    </div>-->

  </div>
</template>
<script>
import {mapGetters} from "vuex";

import router from "@/router";
import axios from "axios";

export default {
  name: "Headline",
  props: {
    task: Object,
  },
  computed: {
    ...mapGetters(["taskActive",  "customerActive"])
  },
  methods: {
    setTask(task, subview) {

      // Unset if already set:
      if (this.$route.params && (this.$route.params.id === task.id)) {

        this.$router.push({name: "Issues"});
      } else {
        this.$router.push({name: "IssueDetails", params: {id: task.id}})
      }

    },
    scrollToTop() {
      window.scrollTo(0, 0);

    },
    checkTask(task) {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      const hash = this.task.customerHash;
      let postContent = {
        data: task,
        hash: hash
      }
      // todo-matt: make states dynamic
      postContent.data.state_id = 2;
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          this.$store.dispatch("tasksFilterCustomer");
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    }
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
    }
  }
}
</script>
