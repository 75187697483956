<template>

  <div style="" class="headerWithImage header pt-10 pb-10">

    <div class="container">

      <div class="row">

        <div class="col-12">

          <h1 class="text-uppercase text-white">{{ headline }} </h1>
          <h2 class="d-block text-white">{{ text }}</h2>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'MainHeader',
  props: {

    headline: String,
    text: String

  }

}

</script>
<style lang="scss">
.headerWithImage {
  border-bottom: 12px solid #2aa87d;
  position: relative;
  min-height: 300px;
  background-size: cover;
  background-image: url('https://res.cloudinary.com/dfb-de/image/fetch/c_fill,f_auto,g_center,h_637,q_auto,w_1920/https://assets.dfb.de/uploads/000/217/471/preview_cropped_trainer_ausbildung_fortbildun_weiterbildung_fussball_coaching.jpg%3F1578925956');
  z-index: 100;
  &:before {
    background-color: #004941;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    content: "";
    opacity: .55;
  }
  .container {
    position: relative;
    z-index: 301;



  }
}

</style>
