<template>

<div class="container appView tasks">

  <div class="row mt-3">

    <div class="col-12">

      <div class="card">
        <div class="card-body">
          <CreateIssue @update="getGroups" />
          <h1>Tickets</h1>
          <Details v-if="$route.params.id"/>
          <div class="taskList" v-if="issues && issues.length > 0">
          <List :issues="issues" v-if="!$route.params.id" />
          </div>
          <div class="feedbacks mt-3" v-else>
            <div class="alert alert-info" role="alert">
              <i class="fas fa-info-circle"></i> Keine Tickets vorhanden
            </div>
          </div>
        </div>

      </div>



    </div>
  </div>
</div>
</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";
import CreateIssue from "@/views/Issues/Create.vue";
import {ref} from "vue";
import VuePdfEmbed from 'vue-pdf-embed'
import Details from "./Issues/Details.vue";
import List from "./Issues/List.vue";
export default {

  name: 'Issues',
  components: {Details, VuePdfEmbed, CreateIssue, List},
  data() {
    return {
     issues: [],
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"])
  },

  methods: {
    getGroups() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let offset = 0;
      let limit = 100;
      axios.get(this.$store.state.apiUrl + "issue/search?offset=" + offset + "&limit=" + limit, {headers})
        .then(response => {
          this.issues = response.data.result.data;
        })
        .catch(e => {
          console.log(e);
        });
    },


  },

  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  created() {

this.getGroups();

  },


  watch: {
    $route(to, from) {
      if (to.params.id) {
        this.getGroups();
      }
    }
  }

}

</script>
