<template>
  <div class="dropWrapper">
    <template v-if="!compact">
    <h2><i class="fa-regular fa-files"></i> Dateien hochladen</h2>
    <p>Gewünschte Dateien in das Fenster ziehen oder wählen Sie ein oder mehrere Dokumente aus, die Sie hochladen
      möchten.</p></template>
    <div class="upload" :style="(compact) ? '':'margin-bottom: 48px;'">
      <ul  v-if="!compact">
        <li v-for="file in files" :key="file.id">
          <span>{{ file.name }}</span> -
          <span>{{ file.size }}</span> -
          <span v-if="file.error">{{ file.error }}</span>
          <span v-else-if="file.success">Upload beendet!</span>
          <span v-else-if="file.active">Upload läuft...</span>
          <span v-else-if="!!file.error">{{ file.error }}</span>
          <span v-else></span>
        </li>
      </ul>
      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
        <h3><i class="fa-regular fa-files"></i> Dateien in das Fenster ablegen</h3>
      </div>
      <file-upload
        class="btn btn-primary"
        :class="(compact) ? 'btn-sm':'me-3'"
        :post-action="postAction"
        extensions="gif,jpg,jpeg,png,webp,pdf"
        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf,image/svg+xml,text/plain"
        :multiple="true"
        :drop="true"
        :drop-directory="false"
        :size="1024 * 1024 * 10"
        v-model="files"
        @input-filter="inputFilter"
        @input-file="inputFile"
        :headers="headers"
        ref="upload">
        <i class="fa-regular fa-upload"></i> Dateien auswählen
      </file-upload>
      <button type="button" class="btn btn-outline-danger me-4" v-if="$refs.upload && $refs.upload.active"
              @click.prevent="upload.active = false">
        <i class="fa-regular fa-close"></i> Upload stoppen
      </button>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue'
import FileUpload from 'vue-upload-component'
import {useToast} from "vue-toastification";

export default {
  name: 'UploadComponent',
  components: {
    FileUpload,
  },
  props: {
    customerId: '',
    taskId: '',
    compact: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkUpload() {
      if (!this.upload) {
        this.upload = {
          active: false
        }
      }
      if (!this.customerId) {
        this.postAction = this.$store.state.apiUrl + 'files/uploadTemp'
        this.upload.active = true;
      } else {
        if (this.taskId) {
          this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload/' + this.taskId + '/issue'
        } else {
          this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload'
        }
        this.upload.active = true;
      }
    },
    denyUpload() {
      this.upload.active = false;
    }
  },
  data() {
    return {
      postAction: '',
      upload: {
        active: false
      },
      reference: 'upload',
      headers: {
        'crmAuth': localStorage.getItem('dfbToken')
      },
    }
  },
  setup(props, context) {
    const upload = ref(null)
    const files = ref([])

    function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (newFile.size > 1024 * 1024 * 7) {

          const toast = useToast();

          toast.error("Datei zu groß. Maximal 7 MB erlaubt.");

          prevent(); // Prevent the file from being added
          return;
        }
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    }

    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        const triggerReloadTask = new CustomEvent('fileAddedInput');
        window.dispatchEvent(triggerReloadTask);
      }
      if (newFile && oldFile) {
        // update
        if (newFile.active === false) {
          const triggerReloadTask = new CustomEvent('triggerReloadFiles');
          window.dispatchEvent(triggerReloadTask);
        }
      }
      if (!newFile && oldFile) {
        // remove
        const triggerReloadTask = new CustomEvent('fileRemovedInput');
        window.dispatchEvent(triggerReloadTask);
      }
    }

    return {
      files,
      upload,
      inputFilter,
      inputFile,
    }
  },
  created() {
    if (!this.customerId) {
      this.postAction = this.$store.state.apiUrl + 'files/uploadTemp'
    } else {
      if (this.taskId) {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload/' + this.taskId + '/issue'
      } else {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload'
      }
    }
    window.addEventListener('fileAddedInput', this.checkUpload)
    window.addEventListener("fileRemovedInput", this.denyUpload)
  },
  watch: {
    customerId() {
      if (!this.customerId) {
        this.postAction = this.$store.state.apiUrl + 'files/uploadTemp'
      } else {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload'
      }
    }
  }
}
</script>
<style>
label[for=file] {
  cursor: pointer;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
  text-transform: initial;
}
</style>
