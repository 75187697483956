<template>

  <div class="header headerBigImage">

    <div class="container">

      <div class="row">

        <div class="col-sm-12 col-md-6 col-lg-8">

          <h1 class="text-uppercase text-white">Bewerbungsportal</h1>

          <p class="text-white">Loggen Sie sich ein.</p>

        </div>

        <div class="col-sm-12 col-md-6 col-lg-4">

          <div class="form-group mt-2">

            <label for="email" class="text-white">E-Mail</label>
            <input type="email" class="form-control" id="email" v-model="c.username">

          </div>

          <div class="form-group mt-2">

            <label for="password" class="text-white">Passwort</label>
            <input type="password" class="form-control" id="password" v-model="c.password">

          </div>

          <button href="#" class="btn btn-secondary text-uppercase float-end rounded mt-3" @keyup.enter="login(c)" @click="login(c)">Anmelden</button>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'UserLogin',

  watch: {},

  data () {
    return {
      c: {
        username: '',
        password: '',

      }
    }
  },

  methods: {
    ...mapActions(["login"]),
    getUser () {

    }
  }

}
</script>
