<template>
<!--  <MainHeader headline='Trainerlizenz' text='Erfassen Sie Ihre Trainerlizenz'/>-->

  <div class="card p-1 p-lg-4">
    <div class="card-body">


  <template v-if="attestList && attestList.length > 0">

    <div style="margin-bottom: 49px;" >
      <div class="card p-1 p-lg-4">
        <div class="card-body">
          <h4 class="pb-3 text-center">{{ attestList[0]._attestTitle }}</h4>
          <p class="card-title text-center">Ihr erweitertes Führungszeugnis
            <template v-if="attestList[0].verified ==='verified' || attestList[0].verified === 'verified_complete'"> wurde akzeptiert. </template>
            <template v-else-if="attestList[0].verified === 'waiting'|| attestList[0].verified === 'verified_waiting'"> wurde eingereicht und wird geprüft.</template>
            <template v-else-if="attestList[0].verified === 'denied'"> wurde abgelehnt. </template>
          </p>
          <p class="card-text text-center" v-if="attestList[0].verified ==='verified'|| attestList[0].verified === 'verified_complete'">Ihr erweitertes Führungszeugnis ist gültig bis <strong>{{$filters.formatDate(l.valid_to)}}.</strong></p>
          <p class="card-text commentText" v-if="attestList[0].verified === 'denied' && attestList[0].verified_comment && attestList[0].verified_comment !== ''" style="text-align: center;     font-style: italic;
    margin-top: 24px;" >
            {{attestList[0].verified_comment}}
          </p>
          <p v-if="attestList[0].verified ==='verified' || attestList[0].verified === 'verified_complete'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-check" style="color: green;"></i></p>
          <p v-if="attestList[0].verified ==='waiting'|| attestList[0].verified === 'verified_waiting'" style="text-align: center;" class="fa-4x"><i class="fa-regular fa-circle-question" style="color: #e4c82c;"></i></p>

          <p v-if="attestList[0].verified ==='denied'"  style="text-align: center;" class="fa-4x"  ><i class="fa-regular fa-circle-exclamation" style="color: darkred;"></i></p>
        </div>
      </div>

    </div>

  </template>

      <h4 style="margin-bottom: 24px;">Erweitertes Führungszeugnis</h4>
      <p>Bitte laden Sie hier <strong>Ihr original erweitertes Führungszeugnis</strong> als Nachweis eines tadelfreien Leumunds hoch (<strong>bei Abschluss der aktuell laufenden Bewerbungsphase nicht älter als 3 Monate</strong>).</p><p>

        Nachweise über die Beantragung des erweiterten Führungszeugnis werden nicht akzeptiert und gelten als unvollständig!
</p>
<p><strong>Vor dem Hochladen muss zwingend das Ausstellungsdatum des erweiterten Führungszeugnis im dafür vorgesehenen Feld eingetragen werden.</strong></p>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="truth2" v-model="truth">
        <label class="form-check-label" for="truth2" style="padding-left: 12px;">Hiermit bestätige ich die Richtigkeit und Echtheit des hochgeladenen Dokuments und die dort hinterlegten Angaben. Mir ist bewusst, dass bei Falschangaben ein Lizenzentziehungsverfahren nach § 32 der DFB-Ausbildungsordnung eingeleitet werden kann.</label></div>


      <div class="form-check">

        <input type="checkbox" class="form-check-input" id="privacy2" v-model="privacy">

        <label class="form-check-label" for="privacy2" style="padding-left: 12px;" >
          Ihr erweitertes Führungszeugnis wird ausschließlich zum Zwecke der einmaligen Kontrolle kurzzeitig verarbeitet und nach Sichtung durch den DFB systemseitig unwiderruflich gelöscht. Weitere Informationen zur Datenverarbeitung und Ihren Betroffenenrechten finden Sie in unserer <a href="https://www.dfb.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung.</a></label>  </div>

<div class="row">
  <div class="col-12 col-lg-6">


    <div class="form-floating  mb-3">


    <label for="sellic">Erweitertes Führungszeugnis wählen</label>
  </div>
    <div class="form-floating mb-3">
      <input class="form-control" id="nextLic" :min="minDateThreeMonths" :max="maxDateToday" type="date" v-model="attest.valid_from" placeholder="Ausstellungsdatum des erweiterten Führungszeugnis">
      <label for="nextLic">Ausstellungsdatum des erweiterten Führungszeugnis</label>
    </div>


 </div>
  <div class="col-12 col-lg-6">
    <h5 style="margin-top: 18px;"><template v-if="attest.attest_pdf"><i class="fa-regular fa-check-circle" style="color: green;"></i>&nbsp;</template>Erweitertes Führungszeugnis Datei</h5>
    <template v-if="privacy && truth && isValidDate">
    <UploadComponentPolice :compact="true" v-model="attest.attest_pdf"  v-if="attest.attest_pdf === ''" /></template>
    <template v-else>
      <p v-if="attest.valid_from !== ''">Das erweiterte Führungszeugnis darf nicht älter als 3 Monate bei Abschluss der aktuell laufenden Bewerbungsphase sein.<br>
        Bitte korrektes Dokument hochladen, Datum eintragen und alle Pflichtfelder bestätigen.</p>
    </template>
    <!-- show only if all values are filled -->
    <template v-if="attest.attest_pdf !== '' && attest.valid_from !== '' && privacy && truth ">
      <template v-if="loading">
        <button type="button" class="btn btn-primary" style="margin-top: 39px;" disabled><i class="fa-regular fa-spinner fa-spin"></i> Speichern</button>
      </template>
      <template v-else>


      <button type="button" class="btn btn-primary" style="margin-top: 39px;" @click="submitToServer()">Speichern</button>
      </template>
    </template>
    <template v-else>

      <button type="button" class="btn btn-primary"  style="margin-top: 155px" disabled >Speichern nach Upload möglich</button>
    </template>
  </div>
</div>

    </div>
  </div>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import UploadComponentPolice from "@/components/UploadComponentPolice.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'PoliceView',
  components: {

    MainHeader, UploadComponentPolice
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
    isValidDate() {
      const validFrom = new Date(this.attest.valid_from);
      const minDate = new Date(this.minDateThreeMonths);
      const maxDate = new Date(this.maxDateToday);
      return validFrom >= minDate && validFrom <= maxDate;
    }
  },
  data() {
    return {
      attests: [],
      attestList: [],
      maxDateToday: new Date().toISOString().split('T')[0],
      minDateThreeMonths: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().substr(0, 10),
      attest: {
        attest_type: 'police',
        valid_from: '',
        valid_to: '',
        attest_pdf: '',
        verified: '',
      },
      privacy: false,
      truth: false,
      loading: false
    }
  },

  methods: {
    submitToServer() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'trainer/attest'  , this.attest, {
        headers
      })
        .then(response => {
          this.getAttest();
          const triggerReloadTask = new CustomEvent('triggerReloadTask');
          window.dispatchEvent(triggerReloadTask);
          this.attest = {
            attest_type: 'police',
            valid_from: '',
            valid_to: '',
            attest_pdf: '',
            verified: ''
          };

        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getAttest(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'trainer/attest/list/police'  , {
        headers
      })
        .then(response => {
          this.attestList = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

  },
  created() {

    this.getAttest();
  }
}
</script>
