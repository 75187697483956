<template>

  <div v-if="task" style="margin-bottom: 48px;">

    <button v-if="task.status === 'open'" @click="setState('completed')" class="btn btn-success"><font-awesome-icon :icon="['far', 'check']"/> Diese Aufgabe als erledigt markieren!</button>
    <button v-else class="btn btn-danger" @click="setState('open')"><font-awesome-icon :icon="['far', 'backward']"/>  Aufgabe zurücksetzen</button>

  </div>


</template>

<script>


import axios from "axios";
import {useToast} from "vue-toastification";

export default {
  name: 'CheckTask',

  props: {
    task: null
  },

  data() {
    return {

    }
  },
  methods: {
    setState(state) {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.put(this.$store.getters.getApiUrl + 'task/'+this.task.id+'/setState', {state:state},{headers})
        .then(response => {
          const toast = useToast();
          toast.success("Aufgabe wurde aktualisiert");
          const triggerReloadTask = new CustomEvent('triggerReloadTask');
          window.dispatchEvent(triggerReloadTask);
        })
        .catch(error => {

        })
    }
  },

  created() {



  },

}
</script>

