<template>
  <MainHeader headline="Ihre Bewerbung" text=""/>
  <div class="container">
    <nav class="mt-4 nav nav-pills flex-column flex-sm-row">
      <button @click="$store.state.currentProgress = 1" :class="{active: (getCurrentProgress === 1)}"
              class="flex-sm-fill text-sm-center nav-link">{{ $t('application.steps.1') }}
      </button>
      <button @click="$store.state.currentProgress = 2" :class="{active: (getCurrentProgress === 2)}"
              class="flex-sm-fill text-sm-center nav-link">{{ $t('application.steps.2') }}
      </button>
      <button @click="$store.state.currentProgress = 3" v-if="getCurrentApplication.use_questions !== 'no'"
              :class="{active: (getCurrentProgress === 3)}" class="flex-sm-fill text-sm-center nav-link">
        {{ $t('application.steps.3') }}
      </button>
      <button @click="$store.state.currentProgress = 4" v-if="getCurrentApplication.use_questions !== 'no'"
              :class="{active: (getCurrentProgress === 4)}" class="flex-sm-fill text-sm-center nav-link">
        {{ $t('application.steps.4') }}
      </button>
      <button @click="$store.state.currentProgress = 5" v-if="getCurrentApplication.use_questions !== 'no'"
              :class="{active: (getCurrentProgress === 5)}" class="flex-sm-fill text-sm-center nav-link">
        {{ $t('application.steps.5') }}
      </button>
      <button @click="$store.state.currentProgress = 6" :class="{active: (getCurrentProgress === 6)}"
              class="flex-sm-fill text-sm-center nav-link">{{ $t('application.steps.6') }}
      </button>
    </nav>
    <div class="progress mt-4 mb-4">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar"
           :style="{width: currentWidth+'%'}" :aria-valuenow="currentWidth" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
  <div class="container mt-4">
    <div class="progress-welcome mb-3">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ApplicationIntroduction :welcome-text="addTargetBlank(getCurrentApplication.welcome_message)"
                                       :info-text="getCurrentApplication.info_text" v-if="getCurrentProgress === 1"/>
              <TasksComponent v-if="getCurrentProgress === 2"/>
              <ApplicationCoachExperiences :calcTypes="calcTypes" v-if="getCurrentProgress === 3"/>
              <ApplicationPlayerExperiences :calcTypes="calcTypes" v-if="getCurrentProgress === 4"/>
              <ApplicationEducations :calcTypes="calcTypes" v-if="getCurrentProgress === 5"/>
              <ApplicationOverview v-if="getCurrentProgress === 6"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApplicationIntroduction from "@/components/application/ApplicationIntroduction";
import ApplicationCoachExperiences from "@/components/application/ApplicationCoachExperiences";
import ApplicationPlayerExperiences from "@/components/application/ApplicationPlayerExperiences";
import ApplicationEducations from "@/components/application/ApplicationEducations";
import TasksComponent from "@/components/Tasks";
import ApplicationOverview from "@/components/application/ApplicationOverview";
import MainHeader from "@/components/MainHeader";
import {mapGetters} from "vuex";
import axios from "axios";
import {nextTick} from 'vue';

export default {
  name: 'ApplicationDetails',
  data() {
    return {
      currentWidth: 0,
      maxSteps: 6,
      singleStepInPercent: 0,
      calcTypes: {}
    }
  },
  components: {
    MainHeader,
    ApplicationOverview,
    TasksComponent,
    ApplicationEducations,
    ApplicationCoachExperiences,
    ApplicationPlayerExperiences,
    ApplicationIntroduction
  },
  computed: {
    ...mapGetters(["getCurrentProgress", "getCurrentApplication"])
  },
  created() {
    this.singleStepInPercent = 100 / this.maxSteps;
    this.getCalcTypes();
  },
  methods: {
	  addTargetBlank(value) {

		  if (typeof value === "string") {
			  return value.replace(/<a/g, '<a target="_blank"');
		  }
		  return value;
	  },
    saveCurrentProgress(currentProgress) {
      this.currentWidth = currentProgress * this.singleStepInPercent;
    },
    getCalcTypes() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.getters.getApiUrl + 'plugins/dfb/calcTypes', {headers})
        .then(response => {
          let shortResponse = response.data.result;
          this.calcTypes = shortResponse.data;
        }).catch(error => {
        this.$log.debug(error)
      })
    }
  },
  watch: {
    '$store.state.currentProgress': function (currentProgress) {
      this.saveCurrentProgress(currentProgress);
    }
  },
  mounted() {
    this.beforeUnloadHandler = (e) => {
      e.preventDefault();
      e.returnValue = 'Sind Sie sicher, dass Sie diese Seite verlassen möchten?';
    };
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  },
}
</script>
