<template>
  <div class="btn-group float-end mb-3">
    <button class="btn btn-primary btn-sm" @click="addSubtask"><i
      class="fa-regular fa-list-tree"></i> Neuer Subtask
    </button>
  </div>
  <div class="container mb-3" style="clear: both;">


      <template v-if="addSubTask">
        <h6 class="editLine subtask" ref="editMe" contenteditable @keypress.enter.prevent="editItem('title')" @keydown.escape="addSubTask = false;">{{
            subtask.title
          }}</h6>
      </template>
      <List :tasks="subtasks" class="w-100" />


  </div>
</template>
<script>
import axios from "axios";
import List from "./Sublist";

export default {
  name: "Subtasks",

  props: {
    task: {
      type: Object
    }
  },
  components: {
    List
  },
  data() {
    return {
      subtasks: [],
      addSubTask: false,
      subtask: {
        title: ''
      }
    };
  },
  methods: {
    addSubtask() {
      this.addSubTask = true;
      // timeout
      this.$nextTick(() => {
        this.$refs.editMe.focus();
      });

    },

    editItem(field) {
      const value = event.target.innerText;
      this.subtask[field] = value;
      this.postSubTask();
    },

    postSubTask() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      const hash = this.task.customer.hash;
      this.task.parent_issue = this.task.id;
      const task = Object.assign({}, this.task);
      task.title = this.subtask.title;
      delete task.id;
      let postContent = {
        data: task,
        hash: hash
      }
      axios.post(this.$store.state.apiUrl + 'issue/', postContent, {headers})
        .then(response => {
          this.getSubtasks(this.task.id);
          this.addSubTask = false;
          this.subtask.title = '';
          this.addSubtask();
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    getSubtasks(taskid){
      // if taskis is object
      if(typeof taskid === 'object')
      {
        taskid = taskid.detail;
      }
      if(taskid === undefined){
        taskid = this.$route.params.id;
      }
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + 'issue/search?filter_issue=' + taskid +'&filter_state=open', {headers})
        .then(response => {
          this.subtasks = response.data.result.data;

        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
      return true;
    },

  },
  mounted() {
    this.getSubtasks(this.task.id);

  },
  beforeUnmount() {

  },
  created() {
    window.addEventListener('reloadSubtasks', this.getSubtasks);
  }
}
</script>
<style scoped>
.editLine.subtask{
  border-bottom: 1px solid #333;
  width: 100%;
  min-height: 24px;
}
</style>
