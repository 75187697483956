<template>
  <i :class="renderClass"></i>
</template>

<script>
export default {
  name: 'font-awesome-icon',
  props: {
    icon: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '1x',
    },
  },
  computed: {
    renderClass() {
      const prefix = 'fa';
      const iconClasses = this.icon.map(item => `${prefix}-${item}`);
      const sizeClass = `fa-${this.size}`;
      return [prefix, ...iconClasses, sizeClass].join(' ');
    },
  },
};
</script>
