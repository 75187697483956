
<template>

<template v-for="issue in issues">

  <Headline :task="issue"  />
</template>

</template>
<script>

import Headline from "./Headline.vue";
export default {
  name: "List",
  props: {
    issues: {
      type: Array,
      required: true,
    },
  },
  components:{
    Headline,
  },
}
</script>
<style scoped>
</style>
