<template>
  <div class="container mb-3">

    <div class="container-inner">
      <div class="comments w-100 mb-3">
        <div class="row" v-if="taskActive.comments">
          <div class="col">
            <div class="list-group">
              <template v-for="comment in taskActive.comments">
                <div class="list-group-item" :key="comment.id" v-if="comment.comment">
                  <template v-if="( comment.id === setComment.id ) && editComment">
                    <textarea v-model="setComment.comment"></textarea>
                    <div class="row">
                      <div class="col text-end">
                        <button @click="editComment = false; setComment = {};"
                                class="btn btn-outline-secondary btn-sm me-3"><i
                          class="fa-regular fa-x"></i> Abbrechen
                        </button>
                        <button @click="updateComment" class="btn btn-outline-primary btn-sm me-3"><i
                          class="fa-regular fa-paper-plane"></i> Senden
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="inlineCommentWrap">
                      <div class="infoLine w-100">
                        <small class="avatar"> <b>
                          {{ comment.author }}</b></small><small>{{
                          $filters.formatDateTime(comment.crm_created_date)
                        }}</small>

                      </div>
                      <div class="mb-1" v-html="$filters.renderLineBreaks(comment.comment)"></div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="row"><div class="col">
          <div class="btn-group float-end mb-3 mt-3">
            <button class="btn btn-primary btn-sm" @click="showCommentNew = true" v-if="!showCommentNew"><i
              class="fa-regular fa-comment"></i> Neuer Kommentar
            </button>
          </div>
        </div></div>

        <div class="row">
          <div class="col">
            <Createcomment :task-id="taskActive.id" @close="closeComment" v-if="showCommentNew"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Createcomment from "./Createcomment.vue";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "Comments",
  emits: ['update'],
  props: {
    taskId: "",
    taskActive: ''
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList",  "getUser"])
  },
  components: {
    Createcomment
  },
  data() {
    return {
      editComment: false,
      setComment: {},
      showCommentNew: false,
    }
  },
  methods: {
    setUpdateComment(comment) {
      this.setComment = comment;
      this.editComment = true;
    },
    closeComment() {
      this.showCommentNew = false;
      this.$emit('update');

    },

  }
}
</script>
<style scoped>
</style>
