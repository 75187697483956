<template>
  <div class="card" v-if="taskItem && taskItem.infos" >
    <div class="card-header">
      <button class="btn-close float-end" @click="$router.push({name: 'Issues'})"></button>
      <h5>#{{taskItem.hash}} | {{ taskItem.title }}</h5>
      <div class="infoBlock text-xs" >
        <div class="row">
        <template v-if="taskItem.infos && taskItem.infos.author">
          <div class="col">
        <i
        class="fa-regular fa-calendar-circle-plus" title="Erstellt"></i>
            {{ $filters.formatDate(taskItem.crm_created_date) }}<br>{{ taskItem.infos.author }} </div></template>
        <template v-if="taskItem.infos && taskItem.infos.editor">

        <div class="col">
        <i
          class="fa-regular fa-calendar-circle-user" title="Zuletzt bearbeitet"></i>{{ $filters.formatDateTime(taskItem.crm_modified_date) }}<br>{{ taskItem.infos.editor }}
        </div>
        </template>
        <div class="col">
          <label class="color">Status</label>
          <span v-if="taskItem.infos.state && taskItem.infos.state.title">{{ taskItem.infos.state.title }}</span>
        </div>
        <div class="col">
          <label class="color">Priorität</label>
          <span v-if="taskItem.infos.priority && taskItem.infos.priority.title">{{ taskItem.infos.priority.title }}</span>
          <span v-else>-</span>
        </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="taskItem.infos">
      <div class="container mb-3 taskDetails">

        <div class="row">
          <div class="col-lg-9 col-xl-10 col-12">

            <div class="row">
              <div class="col-12">
                <hr/>
                <div class="description mb-3" v-if="taskItem.description" v-html="taskItem.description">
                </div>
                <hr/>
              </div>
            </div>
            <div class="row">
<!--              <div class="col">-->
<!--                <label class="color">Beginn</label>-->
<!--                <span v-if="taskItem.start_date">-->
<!--                  {{ $filters.formatDate(taskItem.start_date) }}-->
<!--                </span>-->
<!--                <span v-else>&#45;&#45;.&#45;&#45;.&#45;&#45;&#45;&#45;</span>-->
<!--              </div>-->
<!--              <div class="col">-->
<!--                <label class="color">Abgabedatum</label>-->
<!--                <span v-if="taskItem.due_date"> {{ $filters.formatDate(taskItem.due_date) }}</span>-->
<!--                <span v-else>&#45;&#45;.&#45;&#45;.&#45;&#45;&#45;&#45;</span>-->
<!--              </div>-->

<!--              <div class="col">-->
<!--                <label class="color">Projekt</label>-->
<!--                <span v-if="taskItem.infos.project && taskItem.infos.project.title">{{taskItem.infos.project.title}}</span>-->
<!--                <span v-else>keine Angabe</span>-->
<!--              </div>-->

            </div>
          </div>
          <div class="col-lg-3 col-xl-2 col-12">

            <label class="color">Bearbeiter</label>
            <p v-if="taskItem.infos.employee">{{ taskItem.infos.employee.e_name }}</p>
            <p v-else>wird zugewiesen</p>
            <label class="color">Kontaktpersonen</label>
            <p>
              <template v-if="taskItem.followers && taskItem.followers.outer && taskItem.followers.outer.length">
              <template v-for="follower in taskItem.followers.outer">
                <span style="display: block;">{{ follower._customerName }}</span>
              </template>
            </template>
              <template v-else>
                <template v-if="taskItem.customer">
                  <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
                  <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                </template>
              </template>

            </p>
<!--            <label class="color">Mitarbeiter</label>-->
<!--            <p><template v-if="taskItem.followers && taskItem.followers.inner && taskItem.followers.inner.length">-->
<!--              <template v-for="follower in taskItem.followers.inner">-->
<!--                <span style="display: block;">{{ follower.e_name }}</span>-->
<!--              </template>-->
<!--            </template>-->
<!--            <template v-else>-</template>-->
<!--            </p>-->
            <label class="color">Art der Anfrage</label>
            <p v-if="taskItem.infos.tracker && taskItem.infos.tracker.title">{{taskItem.infos.tracker.title}}</p>
            <p v-else>-</p>
          </div>
        </div>
      </div>
      <div class="taskAttachments">
        <Attachments :attachments="taskItem.attachments" :taskActive="taskItem"
                     v-if="taskItem.attachments"></Attachments>
      </div>
      <Comments :taskActive="taskItem" @update="getSingleTask()"/>
<!--      <div class="subTasks">-->
<!--        <Subtasks :task="taskItem" v-if="taskItem"></Subtasks>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Comments from "./Comments.vue";
import axios from "axios";
import Subtasks from "./Subtasks.vue";
import Attachments from "./Attachments.vue";

export default {
  name: "Details",
  components: {
    Subtasks, Attachments,
    Comments
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "getConfigs"])
  },
  data() {
    return {
      taskItem: {},
      taskTabs: [
        {name: 'details', label: 'Details', icon: 'fa-info'},
        {name: 'subtasks', label: 'Unteraufgaben', icon: 'fa-list-tree'},
        {name: 'attachments', label: 'Anhänge', icon: 'fa-paperclip'},
        {name: 'comments', label: 'Verlauf/Kommentare', icon: 'fa-comments'},
      ],
      activeSubtab: 'details',
      editStart: false,
      editDue: false,
      editAssignee: false,
      editState: false,
      editPrio: false,
      editTracker: false,
      editExtFollower: false,
      editIntFollower: false,
      editProject: false,
      editCustomer: false,
      addresses: [],
      projects: []
    }
  },
  methods: {
    closeTask() {
      this.$store.dispatch("tasksFilterCustomer");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Issues"});
    },
    getSingleTask() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + 'issue/' + this.$route.params.id, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          this.taskItem.description = this.$filters.renderLineBreaks(this.taskItem.description);
          this.getAddresses();
          this.getProjects();
          this.scrollToTop();
        })
        .catch(e => {
          console.log(e);
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.taskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    },
    getTitleById(id, type) {
      return Object.values(this.getConfigs[type]).find(issueState => issueState.id === id)?.title || null;
    },
    getAddresses() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.taskItem.crm_customer_id + "/addresses", {headers}).then((r) => {
        this.addresses = r.data.result.data;
      });
    },
    correctId(key) {
      this.taskItem[key] = this.taskItem[key].id;
      this.updateTask();
      this.editAssignee = false;
      this.editState = false;
      this.editTracker = false;
      this.editPrio = false;
      this.editProject = false;
    },
    editItem(field) {
      const value = event.target.innerText;
      this.taskItem[field] = value;
      this.updateTask();
    },
    updateTask() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      const hash = this.taskItem.customer.hash;
      let postContent = {
        data: this.taskItem,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + 'issue/' + this.taskItem.id, postContent, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          this.$store.dispatch('setTask', this.taskItem);
          this.editStart = false;
          this.editDue = false;
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    checkTask() {
      // todo-ralf: correct state_id after API is ready
      this.taskItem.state_id = 2;
      this.updateTask();
      this.$router.push({name: "Tasks"});
      //this.$store.dispatch("tasksFilter");
      // Await refresh then this.closeTask
      setTimeout(() => {
        this.closeTask();
      }, 1000);
    },
    deleteTask(id) {
      this.$confirm('Soll der Eintrag wirklich gelöscht werden?', 'Task löschen', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("dfbToken")};
        axios.delete(this.$store.state.apiUrl + 'issue/' + id, {headers})
          .then(response => {
            this.$store.dispatch('setTask', null);
            this.$router.push({name: "Tasks"});
            this.$store.dispatch('toastSuccess', 'Task gelöscht');
          })
          .catch(e => {
            this.$store.dispatch("handleApiResponse", e.response);
          });
      }).catch(e => {
        console.log(e);
      });
    },
    setActiveSubtabFromRoute() {
      const {tab} = this.$route.params;
      if (tab && this.isValidTab(tab)) {
        this.activeSubtab = tab;
      } else {
        this.activeSubtab = 'details';
      }
    },
    isValidTab(tab) {
      return this.taskTabs.some((taskTab) => taskTab.name === tab);
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
      this.$router.push({name: 'TasksTabs', params: {id: this.$route.params.id, tab: tabName}});
    },
  },
  created() {
    this.getSingleTask();
    this.setActiveSubtabFromRoute();
    window.addEventListener("triggerReloadFiles", this.getSingleTask);
  },
  mounted() {
    Pusher.logToConsole = false;
    var pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    let VueInner = this;
    var channel = pusher.subscribe('42Aj4EUnVYfqZd8F8MHtBjve549pV6GdwVUu4qZJLjtvfRTApv77jMfm9CZ7zTUE');
    channel.bind('issue-'+this.taskItem.id, function (data) {
      VueInner.getSingleTask();
    });
  },
  watch: {
    $route(to) {
      if (to.params.tab) {
        this.activeSubtab = to.params.tab;
      } else {
        this.activeSubtab = 'details';
      }
      if (to.params.id) {
        this.getSingleTask();
      }
    }
  },
}
</script>
<style>
.multiselect.small.open .multiselect__select:before {
  display: none;
}
p:empty {
  display: none;
}
</style>
