<template>
  <MainHeader headline="Login" text="Logindaten werden überprüft" />
  <div></div>
</template>
<script>
import {
  TokenRequest,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  AuthorizationNotifier,
  FetchRequestor, LocalStorageBackend, DefaultCrypto
} from '@openid/appauth';
import MainHeader from "@/components/MainHeader";
import { NoHashQueryStringUtils } from '@/auth/noHashQueryStringUtils';
import environment from '../auth/environment';
import {useToast} from "vue-toastification";

export default {
  name: 'Auth',
  components: {
    MainHeader
  },
  data() {
    return {
      info: null
    }
  },
  created() {
     this.authMe();
  },
  methods: {
    getProfile() {

      const fetchUserInfo = async (token) => {
        const res = await fetch(`${environment.OPServer}${environment.userInfoEndpoint}`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        });
        return res.json();
      };
      const fetchToken = localStorage.getItem('access_token');
      fetchUserInfo(fetchToken)
        .then((info) => {
          this.info = info;
          const toast = useToast();
          toast.success('Herzlich Willkommen, ' + info.name+ '!');
          this.$store.commit("")

        })
    },
    authMe() {
      let VueInner = this;
      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
      const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
      const notifier = new AuthorizationNotifier();
      authorizationHandler.setAuthorizationNotifier(notifier);
      notifier.setAuthorizationListener((request, response, error) => {

        if (response) {


          let extras = null;
          if (request && request.internal) {
            extras = {};
            extras.code_verifier = request.internal.code_verifier;
          }

          const tokenRequest = new TokenRequest({
            client_id: environment.clientId,
            redirect_uri: environment.redirectURL,
            grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
            code: response.code,
            refresh_token: undefined,
            extras
          });

          AuthorizationServiceConfiguration.fetchFromIssuer(environment.OPServer, new FetchRequestor())
            .then((oResponse) => {
              const configuration = oResponse;
              return tokenHandler.performTokenRequest(configuration, tokenRequest);
            })
            .then((oResponse) => {

              VueInner.$store.dispatch("login", {tc:btoa(oResponse.accessToken)});

            })
            .catch(oError => {

            });
        }
      });

      //////////////////////////
      const params = new URLSearchParams(window.location.search);


      if (!params.get('code')) {
        VueInner.$store.commit("logout");

      }
      else {
      authorizationHandler.completeAuthorizationRequestIfPossible();
      }
    }
  }
}
</script>
