<template>




 <div class="container mb-3 w-100">
   <div class="btn-group float-end mb-3">
     <UploadComponent :customer-id="taskActive.crm_customer_id" :task-id="taskActive.id" :compact="true" />
   </div>
  <ul class="fileList list-group w-100">
    <template v-if="attachments" v-for="at in attachments">
      <li class="list-group-item"><i class="fa-regular fa-paperclip" @click="downloadFile(at.customer_id, at.id)"></i> <i
        class="fa-regular fa-eye" @click="previewFile(at.customer_id, at.id)"></i>
        {{ at.title }} - {{ at.uploaded_on }}
      </li>
    </template>
  </ul></div>
  <template v-if="file">
    <Modal v-if="previewFile">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            {{file.title}}
            <button type="button" class="btn-close" @click="closeFile()"></button>

          </div>
          <div class="modal-body">
            <embed :src="'data:'+file.contentType+';base64,'+file.content">
          </div>
        </div>
      </div>
    </div></Modal>
  </template>
</template>
<script>
import axios from "axios";
import UploadComponent from "@/components/UploadComponentIssues.vue";
import {mapGetters} from "vuex";

export default {
  name: "Attachments",
  props: {
    attachments: "",
    taskActive: ''
  },
  components: {
    UploadComponent
  },
  computed: {
    ...mapGetters(["modalsList" ])
  },
  data() {
    return {
      file: '',
      viewPreviewFile: false
    };
  },
  methods: {
    previewFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          // show in modal
          this.viewPreviewFile = true
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeFile() {
      this.file = '';
      this.viewPreviewFile = false;
    },
    downloadFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>
