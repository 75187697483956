<template>

  <div class="header headerBigImage">

    <div class="container">

      <div class="row">

        <div class="col-sm-12 col-md-12 col-lg-12">

          <h1 class="text-uppercase text-white">Bewerbungsportal</h1>
          <p class="text-white">Erlangen Sie Ihre Lizenz und werden Sie Trainer*in für den Deutschen Fußball-Bund.</p>
          <button @click="$router.push({name:'login'})" class="btn p-3 btn-secondary">Mit MEIN.DFB-Konto anmelden</button>

        </div>

<!--        <div class="col-sm-12 col-md-6 col-lg-4">-->



<!--          <div class="row mt-2">-->

<!--            <div class="col-6">-->

<!--              <div class="form-group">-->

<!--                <label for="firstname" class="text-white">Vorname</label>-->
<!--                <input type="text" class="form-control" id="firstname" v-model="c.c_firstname">-->

<!--              </div>-->

<!--            </div>-->

<!--            <div class="col-6">-->

<!--              <div class="form-group">-->

<!--                <label for="lastname" class="text-white">Nachname</label>-->
<!--                <input type="text" class="form-control" id="lastname" v-model="c.c_lastname">-->

<!--              </div>-->

<!--            </div>-->

<!--          </div>-->

<!--          <div class="form-group mt-2">-->

<!--            <label for="birthday" class="text-white">Geburtsdatum</label>-->
<!--            <input type="date" class="form-control" id="birthday" v-model="c.c_birthday">-->

<!--          </div>-->

<!--          <div class="form-group mt-2">-->

<!--            <label for="email" class="text-white">E-Mail</label>-->
<!--            <input type="email" class="form-control" id="email" v-model="c.c_email">-->

<!--          </div>-->

<!--          <button href="#" class="btn btn-secondary text-uppercase rounded mt-3" @click="createUser()">Als Bewerber*in registrieren</button>-->

<!--        </div>-->

      </div>

    </div>

  </div>



</template>

<script>
import axios from 'axios'


export default {
  name: 'UserRegister',

  watch: {},

  data () {
    return {
      c: {
        c_firstname: '',
        c_lastname: '',
        c_birthday: '',
        c_email: '',
      },

    }
  },

  methods: {

    createUser () {

      let Vueinner = this;

      if (this.c.c_firstname === '' || this.c.c_lastname === ''|| this.c.c_birthday === ''|| this.c.c_email === '') {
        this.$toast.error('Bitte füllen Sie alle Felder aus!');
      }
else {
      axios.post(this.$store.state.apiUrl + 'registration', this.c)
        .then(response => {
          let login = {
            username: response.data.data[0],
            password: response.data.data[1]
          }
          this.$store.dispatch('login', login);
        })
        .catch(error => {
          Vueinner.$store.dispatch('handleApiError', error);
        })
      }
    },



  }

}
</script>
<style lang="scss">
.headerBigImage {
  background: url("https://assets.dfb.de/uploads/000/213/120/preview_cropped_manuel_mbom_dribbling_fussball_talentsuche.jpg");
  background-size: cover;
  border-bottom: 12px solid #2aa87d;
  position: relative;

  z-index: 100;
  &:before {
    background-color: #004941;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    content: "";
    opacity: .55;
  }
  .container {
    position: relative;
    z-index: 301;



  }

}
</style>
