<template>
  <template v-if="!options || !options.nationalspieler || !activeAnswers">
    <preloader />
  </template>
  <template v-else>
  <div class="row">
    <div class="col-12">
      <div class="clearfix">
        <div class="row">
          <div class="cols-md-6"><h4>{{ options.vereinsspieler.infos.title }}</h4></div>
          <div class="cols-md-6">
            <button type="button" @click="showInput4 = 1" class="btn btn-secondary float-end mb-3"
                    v-if="$store.state.currentApplicationClosedState === false && showInput4 === 0">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <span class="ms-3">Station hinzufügen</span></button>
          </div>
        </div>


      </div>
      <Modal class="inputBlock mb-3" v-if="showInput4 === 1">
        <div class="container">
          <div class="card p-lg-5 p-3">
            <h2 class="mb-4">{{ options.vereinsspieler.infos.title }} hinzufügen</h2>
            <div class="description mb-4" v-html="options.vereinsspieler.infos.description"></div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpieler.verein" class="form-control" placeholder="Verein">
                  <label for="floatingInput">Verein</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpieler.altersklasse" class="form-control"
                         placeholder="Altersklasse">
                  <label for="floatingInput">Altersklasse</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpieler.liga" class="form-control" placeholder="Liga">
                  <label for="floatingInput">Liga</label>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-floating mb-3">
                      <input type="date" v-model="valuesSpieler.dateStart" :max="$store.state.maxDateValue" class="form-control" placeholder="Beginn">
                      <label>Beginn</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-floating mb-3">
                      <input type="date" v-model="valuesSpieler.dateEnd" :max="$store.state.maxDateValue" class="form-control" placeholder="Ende">
                      <label>Ende</label>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input type="number" v-model="valuesSpieler.saisons" class="form-control" placeholder="Saisons" min="0" step="0.50" max="100">
                  <label for="floatingInput">Saisons</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <perfect-scrollbar>
                    <div v-for="(option, key) in options.vereinsspieler.options" v-bind:key="key">
                      <template v-if="option.is_headline === '1'">
                        <label class="labelHeadline"
                               style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                               :for="'option'+key">{{ option.question }}</label>
                      </template>
                      <template v-else>
                      <input :value="option.id" v-model="valuesSpieler.answer_id" type="radio" class="btn-check"
                             :id="'option'+key" autocomplete="off">
                      <label class="btn btn-outline-primary"
                             style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                             :for="'option'+key">{{ option.question }}</label>
                      </template>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <button class="btn btn-danger mb-3" type="button" @click="showInput4 = 0">Abbrechen</button>
              </div>
              <div class="col-6">
                <button v-if="valuesSpieler.answer_id && valuesSpieler.saisons && valuesSpieler.verein && valuesSpieler.dateEnd && valuesSpieler.dateStart && valuesSpieler.liga && valuesSpieler.altersklasse " @click="postValues('vereinsspieler',valuesSpieler ); showInput4=0" type="button"
                        class="btn btn-secondary float-end mb-3">Speichern
                </button>
              </div>
            </div>
          </div>
        </div>

      </Modal>
      <div class="table-responsive">
        <table class="table table-striped table-values">
          <thead v-if="activeAnswers.vereinsspieler &&  activeAnswers.vereinsspieler.items">
          <tr>
            <th class="del"></th><th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>

            <th>Verein</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th>Saisons</th>

            <th class="text-center" style="width:130px"
               >
              Nachweis
            </th>
            <th class="points-end">Punkte</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in activeAnswers.vereinsspieler.items" v-if="activeAnswers.vereinsspieler &&  activeAnswers.vereinsspieler.items">
            <td>
              <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
              <EntryComment :entry="entry" />
            </td><td>{{ $filters.formatDate(entry.dateStart) }}

          </td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verein }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td>{{ entry.saisons }}</td>

            <td
              >
              <template v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.vereinsspieler && calcTypes.vereinsspieler.nachweis_erforderlich === 'yes'">
                <button @click="startedUpload = entry.id" class="btn w-100" :class="(entry.nachweis && entry.nachweis.length > 0) ? 'btn-warning': 'btn-danger'"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>
                <template  v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
                  </button>
                </template>
              </template>
            </td>
            <td>{{ entry.points }} <template v-if="activeAnswers.diffSum && activeAnswers.diffSum !== '0.00'"><span style="width: 18px;" @click="openInfoBox = 1;" class="btn btn-small btn-danger">!</span></template></td>
          </tr>
          <tr v-else>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="clearfix mb-5"></div>
  <div class="row">
    <div class="col-12">
      <div class="clearfix">
        <div class="row">
          <div class="col-md-6"><h4>{{ options.nationalspieler.infos.title }}</h4></div>
          <div class="col-md-6">
            <button type="button" @click="showInput42 = 1" class="btn btn-secondary float-end mb-3"
                    v-if="$store.state.currentApplicationClosedState === false && showInput42 === 0">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <span class="ms-3">Station hinzufügen</span></button>
          </div>
        </div>


      </div>
      <Modal v-if="openInfoBox === 1">

        <div class="container">
          <div class="card p-lg-5 p-3">
            <h4 class="mb-4">Maximal erreichbare Punktzahl</h4>
            <p>Die maximal erreichbare Punktzahl für die Spieler*in-Erfahrung auf Vereinsebene und in U-Nationalmannschaften ist auf insgesamt 35 Punkte beschränkt.
              <br><br>
              Spiele in A-Nationalmannschaften werden zusätzlich gewertet. Die Spieler*in-Erfahrung in Nationalmannschaften wird abweichend von den Spielen auf Vereinsebene (Saisons) gemäß der Anzahl der absolvierten Spiele bemessen.</p>
          <div class="row">
            <div class="col-6">
              <button class="btn btn-danger" type="button" @click="openInfoBox = 0">Schließen</button>
            </div>

          </div>
        </div>
        </div>
      </Modal>
      <Modal class="inputBlock mb-3" v-if="showInput42 === 1">
        <div class="container">
          <div class="card p-lg-5 p-3">
            <h2 class="mb-4">{{ options.nationalspieler.infos.title }} hinzufügen</h2>
            <div class="description mb-4" v-html="options.nationalspieler.infos.description"></div>
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpielerNat.verband" class="form-control" placeholder="Verband">
                  <label for="floatingInput">Verband</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpielerNat.altersklasse" class="form-control"
                         placeholder="Altersklasse">
                  <label for="floatingInput">Altersklasse</label>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <label>Beginn</label>
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesSpielerNat.dateStart" class="form-control" placeholder="Beginn">
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <label>Ende</label>
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesSpielerNat.dateEnd" class="form-control" placeholder="Ende">
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input type="number" v-model="valuesSpielerNat.saisons" class="form-control" placeholder="Saisons" min="0" step="1" max="100">
                  <label for="floatingInput">Spiele</label>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-floating mb-3">
                  <perfect-scrollbar>
                    <div v-for="(option, key) in options.nationalspieler.options" v-bind:key="key">
                      <template v-if="option.is_headline === '1'">
                        <label class="labelHeadline"
                               style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                               :for="'option'+key">{{ option.question }}</label>
                      </template>
                      <template v-else>
                      <input :value="option.id" v-model="valuesSpielerNat.answer_id" type="radio" class="btn-check"
                             :id="'option'+key" autocomplete="off">
                      <label class="btn btn-outline-primary"
                             style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                             :for="'option'+key">{{ option.question }}</label>
                      </template>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <button class="btn btn-danger" type="button" @click="showInput42 = 0">Abbrechen</button>
              </div>
              <div class="col-6">
                <button v-if="valuesSpielerNat.answer_id && valuesSpielerNat.dateStart && valuesSpielerNat.dateEnd && valuesSpielerNat.verband && valuesSpielerNat.altersklasse &&  valuesSpielerNat.saisons"  @click="postValues('nationalspieler', valuesSpielerNat); showInput42=0" type="button"
                        class="btn btn-secondary float-end">Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values">
          <thead v-if="activeAnswers.nationalspieler && activeAnswers.nationalspieler.items">
          <tr>
            <th class="del"></th><th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>

            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Station</th>
            <th>Spiele</th>

            <th class="text-center" style="width:130px"
               >
              Nachweis
            </th>
            <th class="points-end">Punkte</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in activeAnswers.nationalspieler.items" v-if="activeAnswers.nationalspieler && activeAnswers.nationalspieler.items">
            <td>
              <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
              <EntryComment :entry="entry" />
            </td><td>{{ $filters.formatDate(entry.dateStart) }}

          </td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.question }}</td>
            <td>{{ entry.saisons }}</td>

            <td
              >
              <template v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.nationalspieler && calcTypes.nationalspieler.nachweis_erforderlich === 'yes'">
                <button @click="startedUpload = entry.id" class="btn w-100" :class="(entry.nachweis && entry.nachweis.length > 0) ? 'btn-warning': 'btn-danger'"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>
                <template  v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
                  </button>
                </template>
              </template>
            </td>
            <td>{{ entry.points }} <template v-if="activeAnswers.diffSum && activeAnswers.diffSum !== '0.00'"><span style="width: 18px;" @click="openInfoBox = 1;" class="btn btn-small btn-danger">!</span></template></td>
          </tr>
          <tr v-else>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="clearfix mb-5"></div>
  <div class="row">
    <div class="col-12">
      <div class="clearfix">
        <div class="row">
          <div class="col-md-6"><h4>{{ options.internationalspieler.infos.title }}</h4></div>
          <div class="col-md-6">
            <button type="button" @click="showInput66 = 1" class="btn btn-secondary float-end mb-3"
                    v-if="$store.state.currentApplicationClosedState === false && showInput66 === 0">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <span class="ms-3">Station hinzufügen</span></button>
          </div>
        </div>


      </div>
      <Modal class="inputBlock mb-3" v-if="showInput66 === 1">
        <div class="container">
          <div class="card p-lg-5 p-3">
            <h2 class="mb-4">{{ options.internationalspieler.infos.title }} hinzufügen</h2>
            <div class="description mb-4" v-html="options.internationalspieler.infos.description"></div>
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpielerINat.verband" class="form-control" placeholder="Verband">
                  <label for="floatingInput">Verband</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpielerINat.altersklasse" class="form-control"
                         placeholder="Altersklasse">
                  <label for="floatingInput">Altersklasse</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesSpielerINat.liga" class="form-control" placeholder="Liga">
                  <label for="floatingInput">Liga</label>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <label>Beginn</label>
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesSpielerINat.dateStart" class="form-control"
                             placeholder="Beginn">
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <label>Ende</label>
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesSpielerINat.dateEnd" class="form-control" placeholder="Ende">
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input type="number" v-model="valuesSpielerINat.saisons" class="form-control" placeholder="Saisons" min="0" step="0.50" max="100">
                  <label for="floatingInput">Saisons</label>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-floating mb-3">
                  <perfect-scrollbar>
                    <div v-for="(option, key) in options.internationalspieler.options" v-bind:key="key">
                      <template v-if="option.is_headline === '1'">
                        <label class="labelHeadline"
                               style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                               :for="'option'+key">{{ option.question }}</label>
                      </template>
                      <template v-else>
                      <input :value="option.id" v-model="valuesSpielerINat.answer_id" type="radio" class="btn-check"
                             :id="'option'+key" autocomplete="off">
                      <label class="btn btn-outline-primary"
                             style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                             :for="'option'+key">{{ option.question }}</label>
                      </template>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <button class="btn btn-danger" type="button" @click="showInput66 = 0">Abbrechen</button>
              </div>
              <div class="col-6">
                <button v-if="valuesSpielerINat.answer_id && valuesSpielerINat.verband && valuesSpielerINat.altersklasse && valuesSpielerINat.dateEnd && valuesSpielerINat.dateStart && valuesSpielerINat.saisons"  @click="postValues('internationalspieler', valuesSpielerINat); showInput66=0" type="button"
                        class="btn btn-secondary float-end">Speichern
                </button>




              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values">
          <thead  v-if="activeAnswers.internationalspieler">
          <tr>
            <th class="del"></th><th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th>Saisons</th>

            <th class="text-center" style="width:130px"
                v-if="calcTypes && calcTypes.internationalspieler && calcTypes.internationalspieler.nachweis_erforderlich === 'yes'">
              Nachweis
            </th>
            <th class="points-end">Punkte</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in activeAnswers.internationalspieler.items" v-if="activeAnswers.internationalspieler">
            <td>
              <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
              <EntryComment :entry="entry" />
            </td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>wird vom DFB festgelegt</td>
            <td>{{ entry.saisons }}</td>

            <td
              >
              <template v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.internationalspieler && calcTypes.internationalspieler.nachweis_erforderlich === 'yes'">
                <button @click="startedUpload = entry.id" class="btn w-100" :class="(entry.nachweis && entry.nachweis.length > 0) ? 'btn-warning': 'btn-danger'"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>
              <template  v-for="(nw,index) in entry.nachweis">
                <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                      style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                  <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
              </button>
              </template>
              </template>
            </td>
            <td>{{ entry.points }} <template v-if="activeAnswers.diffSum && activeAnswers.diffSum !== '0.00'"><span style="width: 18px;" @click="openInfoBox = 1;" class="btn btn-small btn-danger">!</span></template></td>
          </tr>
          <tr v-else>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <Modal v-if="activeFile && activeFile.content">

      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-6"><button @click="deleteFile()" class="btn btn-danger">Datei löschen</button></div>
            <div class="col-lg-6"><button @click="activeFile = null;" class="btn btn-success float-end">Vorschau schließen</button></div>
          </div>


          <perfect-scrollbar style="max-height: 80vh">
            <vue-pdf-embed v-if="activeFile.contentType === 'application/pdf'" :source="'data:'+activeFile.contentType+';base64,'+activeFile.content" type="" />
            <embed v-else :src="'data:'+activeFile.contentType+';base64,'+activeFile.content">
          </perfect-scrollbar>
        </div>
      </div>


    </Modal>
    <Modal v-if="startedUpload" >
      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6"><button @click="startedUpload = null;" class="btn btn-success float-end">Upload schließen</button></div>
          </div>
          <UploadComponent :entryId="startedUpload"/>
        </div>
      </div>

    </Modal>
  </template>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import {ref} from "vue";
import UploadComponent from "@/components/UploadComponent";
import Preloader from "@/components/Preloader";
import {useToast} from "vue-toastification";
import VuePdfEmbed from "vue-pdf-embed";
import EntryComment from "@/components/application/EntryComment.vue";

export default {

  name: 'ApplicationPlayerExperiences',
  props: {
    calcTypes: {}
  },
  components: {
    UploadComponent,
    Preloader,
    VuePdfEmbed,
    EntryComment
  },
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  data() {
    return {
      openInfoBox: 0,
      startedUpload: null,
      activeFile: null,
      nav: 1,
      loadingFile: null,
      options: {

      },
      punkte: 0,
      ergebnis: 0,
      values: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verein: null
      },
      valuesNat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesSpieler: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verein: null
      },
      valuesSpielerNat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      }, valuesSpielerINat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesBildung: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      valuesWeiterbildung: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      activeCustomer: {},
      activeAnswers: null,
      showInput: 0,
      showInput1: 0,
      showInput4: 0,
      showInput42: 0,
      showInput66: 0,
      showInput5: 0,
      showInput6: 0
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"])
  },

  created() {
    this.getOptions()
    const headers = {"crmAuth": localStorage.getItem("dfbToken")};
    axios.get(this.getApiUrl + 'customer/' + this.getUser.crm_customer_id, {headers})
      .then(response => {
        let shortResponse = response.data.result;
        this.activeCustomer = shortResponse.data;
        if (this.activeCustomer.id) {
          this.getAnswers();
        }
      })
      .catch(error => {
        this.$log.debug(error)
      })
     window.addEventListener("triggerReloadTask", this.getAnswers)
  },

  methods: {
    openFile(entry, index) {

      this.loadingFile = entry.id+index;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/readFile/' + entry.id+'/'+index, {headers}).then((response) => {
        let shortReport = response.data.result;
        this.activeFile = {entryId: entry.id, index: index, content: shortReport.data.content, contentType: shortReport.data.contentType};
        this.loadingFile = null;
      }).catch((e) => {
        this.$log.debug(e);
        this.loadingFile = null;
      });
    },
    deleteFile() {

      this.$log.debug("delete ---- ", this.activeFile);
      this.deletingFile = this.activeFile.id;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'plugins/dfb/removeFile/' + this.activeFile.entryId+'/'+this.activeFile.index, {headers}).then((response) => {
        this.deletingFile = null;
        this.activeFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      }).catch((e) => {
        this.$log.debug(e);
        this.deletingFile = null;
        this.activeFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      });
    },
    getAnswers() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/answers?requestId='+this.$store.state.currentApplication.id, {headers})
        .then(response => {
          this.$log.debug("answeers: ", response);
          let shortResponse = response.data.result;
          this.$log.debug("short", shortResponse);
          this.activeAnswers = shortResponse.data
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    delEntry(entryId) {

      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'plugins/dfb/answers/' + entryId, {headers})
        .then(response => {
          const toast = useToast();
          toast.success("Eintrag wurde gelöscht");
          this.getAnswers();
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    showNav: function (index) {
      this.nav = index
    },

    postValues: function (postType, values) {

      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'plugins/dfb/' + postType, values, {
        headers
      })
        .then(response => {

          let shortReport = response.data.result;
          this.activeAnswers = shortReport.data;
          this.showInput = 0;

          this.$store.dispatch('setToastsDone');

        })
        .catch(error => {

          this.$store.dispatch('handleApiError', error);
        })
    },
    getOptions: function () {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + 'plugins/dfb/config?requestId='+this.$store.state.currentApplication.id, {headers})
        .then(response => {
          this.options = response.data.result.data
        })
        .catch(error => {
          this.$log.debug(error)
        })
    },
    startUploadInline(entryId) {
      this.startedUpload = entryId;
    },
  },

}

</script>
<style lang="scss">
.labelHeadline {
  font-weight: bold;
  color: #054d45;
  padding-left:12px;
}
</style>
