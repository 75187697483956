<template>
  <button class="btn btn-primary float-end" @click="showNewTicket = true" v-if="!$route.params.id"><i class="fa-regular fa-plus"></i> Neues Ticket</button>
  <Modal v-if="showNewTicket">
  <div class="modal fade show " tabindex="-1"
       aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Neues Ticket</h5>
          <button type="button" class="btn-close" @click="showNewTicket=false"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input type="text" class="form-control mb-1" id="projectTitle" v-model="newTaskItem.title" maxlength="50" placeholder="Titel">
            <div class="counter text-xs text-end">{{newTaskItem.title.length}} / 50</div>
          </div>
          <div class="form-group">
            <textarea v-model="newTaskItem.description" placeholder="Beschreibung (optional)" class="form-control mb-1" maxlength="240"></textarea>
            <div class="counter text-xs text-end">{{newTaskItem.description.length}} / 240</div>
          </div>
          <div class="form-group">
            <label for="projectTitle">Art der Supportanfrage</label>
            <select class="form-select" v-model="newTaskItem.tracker_id">
              <option value="">- Bitte wählen -</option>
              <option v-for="tracker in issue_trackers" :key="tracker.id" :value="tracker.id">{{tracker.title}}</option>
            </select>
          </div>
<div class="form-group mt-3"><p><strong>Hinweis:</strong> Dateianhänge können nach Erstellung des Tickets jederzeit angehängt werden.</p></div>
        </div>
        <div class="modal-footer" style="border: 0;">
          <div class="row w-100">

            <div class="col" >
              <button type="button" class="btn  btn-secondary text-uppercase" @click="postTask" :disabled="(newTaskItem.title.length < 10)">
                <i class="fa-regular fa-ticket"></i> Ticket erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></Modal>
</template>
<script>

import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Create",
  emits: ['close', 'update'],

  computed: {
    ...mapGetters(["getUser" ]),
  },
  components: {  },
  data() {
    return {
      showNewTicket: false,
      newTaskItem: {
        title: "",
        description: "",
        start_date: "",
        due_date: "",
        date_id: '',
        project: {},
        task_id: '',
        customer: {
          id: '',
          hash: '',
          full_name: ''
        },
        tracker_id:''
      },
      issue_trackers: [],
      projects: [],
      dates: []
    };
  },
  methods: {
    postTask() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let hash = "";

      delete this.newTaskItem.customer;
      if(this.newTaskItem.employee_id ) {
        this.newTaskItem.employee_id = this.newTaskItem.employee_id.id;
      }
      let postContent = {
        hash: hash,
        data: this.newTaskItem,
      }
      postContent.data.project_id = this.newTaskItem.project.id;

      // if (this.stateFilter && this.stateFilter.value) {
      //   postContent.data.state_id = this.stateFilter.value;
      // } else {
        postContent.data.state_id = 5;
      // }
      axios.post(this.$store.state.apiUrl + "issue/", postContent, {headers}).then((r) => {
        this.closeTask();
        this.$emit('update');
        //this.$router.push({name: "tasks", params: {id: r.data.result.data.id}});
      });
    },
    closeTask() {
      this.showNewTicket = false;
      this.newTaskItem = {
        title: "",
          description: "",
          start_date: "",
          due_date: "",
          date_id: '',
          project: {},
        task_id: '',
          customer: {
          id: '',
            hash: '',
            full_name: ''
        }
      }
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.newTaskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    },
    getTrackers(){
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + "configs/issue/trackers", {headers}).then((r) => {
        this.issue_trackers = r.data.result.data;
      });
    }

  },
  created() {
  this.getTrackers();
    if (this.taskActive) {
      this.newTaskItem = this.taskActive;

    }
  },
  mounted(){

  },
  watch: {
    newTaskItem: {
      handler: function (val, oldVal) {
        if (val.customer && val.customer.id) {
          this.getProjects();
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped>

</style>
