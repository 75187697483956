<template>


  <template v-if="!specialOrderApplication">
  <h2>Dateien hochladen</h2>
  <p>Wählen Sie ein oder mehrere Dokumente aus, die Sie hochladen möchten.</p>
  </template>
  <div class="upload" :style="'margin-bottom: 48px;'">
    <ul>
      <li v-for="file in files" :key="file.id">
        <span>{{ file.name }}</span> -
        <span>{{ file.size }}</span> -
        <span v-if="file.error">{{ file.error }}</span>
        <span v-else-if="file.success">Upload beendet!</span>
        <span v-else-if="file.active">Upload läuft...</span>
        <span v-else-if="!!file.error">{{ file.error }}</span>
        <span v-else></span>
      </li>
    </ul>


    <file-upload
      :style="'padding:6px !important;'"
      class="btn btn-warning mb-2"
      :class="'me-3'"
      :post-action="postAction"
      extensions="gif,jpg,jpeg,png,webp,pdf"
      accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
      :multiple="true"
      :size="1024 * 1024 * 10"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      :headers="headers"
      ref="upload">

      Dateien auswählen

    </file-upload>

    <button type="button" class="btn btn-success me-4 mb-2" v-if="!upload || !upload.active"
            @click.prevent="upload.active = true">
      Upload starten
    </button>
    <button type="button" class="btn btn-danger me-4 mb-2" v-else @click.prevent="upload.active = false">

      Upload stoppen
    </button>

  </div>


</template>

<script>

import {ref} from 'vue'
import FileUpload from 'vue-upload-component'

export default {
  name: 'UploadComponent',
  components: {
    FileUpload,
  },
  props: {
    specialOrderApplication: null,
    taskId: '',
    entryId: '',
  },
  methods: {
    checkUpload() {
      if (this.specialOrderApplication) {
        this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadSpecialOrder/' + this.$store.state.user.crm_customer_id + '/' + this.specialOrderApplication.id;
      } else {
        if (!this.taskId) {
          if (this.entryId) {
            this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadAnswerNachweis/' + this.$store.state.user.crm_customer_id + '/' + this.entryId

          }
          this.upload.active = true;
        }
      }
    }, denyUpload() {
      if (!this.taskId) {
        this.upload.active = false;
      }
    }
  },
  data() {
    return {
      postAction: '',
      reference: 'upload'
    }
  },
  setup(props, context) {
    const upload = ref(null)


    const files = ref([])

    function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    }

    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        const triggerReloadTask = new CustomEvent('fileAddedInput');
        window.dispatchEvent(triggerReloadTask);
      }
      if (newFile && oldFile) {
        // update

        if (newFile.active === false) {
          const triggerReloadTask = new CustomEvent('triggerReloadTask');
          window.dispatchEvent(triggerReloadTask);
        }
      }
      if (!newFile && oldFile) {
        // remove

        const triggerReloadTask = new CustomEvent('fileRemovedInput');
        window.dispatchEvent(triggerReloadTask);
      }
    }

    return {
      files,
      upload,
      inputFilter,
      inputFile,
    }

  },
  created() {

    this.headers = {"crmAuth": localStorage.getItem("dfbToken")};
    if (this.specialOrderApplication) {
      this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadSpecialOrder/' + this.$store.state.user.crm_customer_id + '/' + this.specialOrderApplication.id;
    }
    else {
    if (this.entryId) {
      this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadAnswerNachweis/' + this.$store.state.user.crm_customer_id + '/' + this.entryId

    } else {
      this.postAction = this.$store.state.apiUrl + 'customer/' + this.$store.state.user.crm_customer_id + '/upload/' + this.taskId;

    }
    }
    window.addEventListener('fileAddedInput', this.checkUpload)
    window.addEventListener("fileRemovedInput", this.denyUpload)

  },
  watch: {
    entryId() {
      if (this.specialOrderApplication) {
        this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadSpecialOrder/' + this.$store.state.user.crm_customer_id + '/' + this.specialOrderApplication.id;
      }
      else {
      if (this.entryId) {
        this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadAnswerNachweis/' + this.$store.state.user.crm_customer_id + '/' + this.entryId
      } else {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.$store.state.user.crm_customer_id + '/upload/' + this.taskId
      }
      }
    },
    specialOrderApplication() {
      if (this.specialOrderApplication) {
        this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadSpecialOrder/' + this.$store.state.user.crm_customer_id + '/' + this.specialOrderApplication.id;
      }
      else {
      if (this.entryId) {
        this.postAction = this.$store.state.apiUrl + 'plugins/dfb/uploadAnswerNachweis/' + this.$store.state.user.crm_customer_id + '/' + this.entryId
      } else {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.$store.state.user.crm_customer_id + '/upload/' + this.taskId
      }
      }
    }
  }

}
</script>
<style>
label[for=file] {
  cursor: pointer;
}
</style>
