<template>
  <div class="addNewCommentWrap">
    <div class="row">
      <div class="col-xl-1 col-12">
        <p class="text-end pt-3">
          {{ comment.author }}
        </p>
      </div>
      <div class="col-xl-11 col-12">
        <div class="commentWrap mt-3">
          <textarea v-model="comment.comment"></textarea>
          <button @click="postComment" class="btn btn-primary me-3"><i class="fa-regular fa-paper-plane"></i>
            Senden
          </button>
          <button @click="$emit('close');" class="btn btn-secondary"><i class="fa-regular fa-x"></i>
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Createcomment",
  emits: ['close'],
  props: {
    taskId: ""
  },
  components: {

  },
  computed: {
    ...mapGetters(["customerActive",  "modalsList", "taskActive", "getUser"]),
  },
  data() {
    return {
      comment: {
        task_id: this.taskId,
        comment: "",
      },
      taskItem: '',
      showCommentNew: false,
    };
  },
  methods: {
    postComment() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let postContent = {
        data: this.comment,
      }
      axios.post(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment", postContent, {headers: headers})
        .then((response) => {
          this.taskItem = response.data.result.data;
          this.$router.push({name: "IssueDetails", params: {id: this.taskItem.id}});
          this.clearComment();
          this.$emit('close');
        })
        .catch((error) => {
          console.log("errerererer");
          this.$store.commit('handleApiError', error)
        });
    },
    clearComment() {
      this.comment = {
        task_id: this.taskId,
        comment: "",
      };
    }
  }
}
</script>
