import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Toast from "vue-toastification";
import FontAwesomeIcon from '@/components/FontAwesome.vue';

import VueSimpleAlert from "vue3-simple-alert-next";


// Import the CSS or use your own!
import "./assets/scss/bootstrap.scss"

import { createI18n } from 'vue-i18n'
import messages from './lang';
const i18n = createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages
})
import 'vue-universal-modal/dist/index.css';

import VueUniversalModal from 'vue-universal-modal';

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'
import VueLogger from 'vuejs3-logger';
const isProduction = process.env.NODE_ENV === 'production';
const LoggerOptions = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
const appInstance = createApp(App);
appInstance.use(VueLogger, LoggerOptions);
appInstance.use(VueCookieComply, );
appInstance.use(store);
appInstance.use(VueSimpleAlert);
appInstance.use(PerfectScrollbar);
appInstance.use(VueUniversalModal, {
  teleportTarget: '#modals',
});
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-right',
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
};



appInstance.use(Toast, options);
appInstance.use(router);
appInstance.use(i18n);
appInstance.config.globalProperties.$filters = {

  priceEuro(value) {
    return Number(value).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €';
  },

  formatDate(value) {

    if (!value || value === '0000-00-00') return "";

    let date = new Date(value);

    // fix safari date
    if (isNaN(date.getTime())) {
      date = new Date(value.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
    }

    const options = {  year: 'numeric', month: '2-digit', day: '2-digit' };

    return date.toLocaleDateString("de-DE", options);
  },
  formatTime(value) {
    let date = new Date(value);
    return date.toLocaleTimeString("de-DE")+" Uhr";
  },
  formatDateTime(value) {
    let date = new Date(value);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString("de-DE", options)+" "+date.toLocaleTimeString("de-DE")+" Uhr";
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  nl2br(value) {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
  },renderLineBreaks(value){
    if(value){
      return value.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");;
    }
  },employeeName(employees, id) {
    if (!employees.length){return "Unbekannt";}
    let employee = employees.filter(obj => {
      return obj.id === id
    });
    employee = employee[0];

    if (employee && employee.e_name) {
      return employee.e_name;
    }
    else {
      return "Unbekannt";
    }

  }
};
appInstance.component('font-awesome-icon', FontAwesomeIcon);
appInstance.mount('#app');
