<template>

  <div>


    <div class="row">

      <div class="col-lg-6">


        <div class="chooseItem">
          <div class="row">
            <div class="col-lg-12" v-for="c in task.chooses">
              <div class="item-choose" v-if="c.closed !== 'hidden'"  :class="(c.closed === 'yes') ? 'closed' : ''">
              <h4>{{c.title}} <template v-if="c.closed === 'yes'">(Ausgebucht!)</template></h4>
              <p  v-html="c.description"></p>
              </div>
              </div>
          </div>



        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-12"><p><strong>Sortieren Sie die Auswahlmöglichkeiten nach Priorität</strong></p>
            <p>Wenn für Sie keine Teilnahme möglich ist, ziehen Sie die betroffene Veranstaltung nach rechts</p></div>
          <div class="col-12 col-sm-6"><h4>Priorität sortieren</h4>
            <draggable
              class="list-group asfa prios mb-3"
              :component-data="{
          tag: 'ul',
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }"
              :list="list.prio"
              group="complete"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
            >
              <template #item="{ element }">
                <li class="list-group-item"  :class="(element.closed === 'yes') ? 'closed' : ''">
                  {{ element.title }} <span v-if="element.closed === 'yes'">(Ausgebucht!)</span>
                </li>
              </template>
            </draggable></div>
          <div class="col-12 col-sm-6">
            <h4>Keine Teilnahme möglich</h4>
            <draggable
              class="list-group asfa mb-3"
              :component-data="{
          tag: 'ul',
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }"
              :list="list.noPrio"
              group="complete"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              item-key="id"
            >
              <template #item="{ element }">
                <li class="list-group-item" :class="(element.closed === 'yes') ? 'closed' : ''">
                  {{ element.title }} <span v-if="element.closed === 'yes'">(Ausgebucht!)</span>
                </li>
              </template>
            </draggable>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12"><button @click="setChoose()" class="mt-3 mb-3 w-100 btn btn-success">Priorisierung abschließen</button></div>
        </div>

      </div>
    </div>

  </div>


</template>
<style>
.item-choose.closed {
  background: #eee !important;
}
.list-group.prios li:nth-child(1):before {
  content: 'Prio 1: ';
}.list-group.prios li:nth-child(2):before {
  content: 'Prio 2: ';
}.list-group.prios li:nth-child(3):before {
  content: 'Prio 3: ';
}.list-group.prios li:nth-child(4):before {
  content: 'Prio 4: ';
}
 .asfa {
   background: #ebf0f6;
   padding:12px !important;
   height: 300px !important;
 }

</style>
<script>

import draggable from "vuedraggable";
import axios from "axios";
import {useToast} from "vue-toastification";
export default {
  name: 'ChooseTask',
  components: {
    draggable,
  },
  props: {
    task: null
  },
  methods: {
    setChoose() {

      if (this.list.prio.length < 1) {
        const toast = useToast();
        toast.error("Bitte mindestens eine Priorität belassen!");
        return;
      }

      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.put(this.$store.getters.getApiUrl + 'task/'+this.task.id+'/setChoose', {choose:this.list, state:'completed'},{headers})
        .then(response => {
          const toast = useToast();
          toast.success("Aufgabe wurde aktualisiert");
          const triggerReloadTask = new CustomEvent('triggerReloadTask');
          window.dispatchEvent(triggerReloadTask);
        })
        .catch(error => {

        })
    }
  },
  data() {
    return {
      isDragging: false,
      drag: false,
      list: {
        prio: [],
        noPrio: [],
      },
      normalList: [

      ]
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  created() {

    // if (this.task.choose && this.task.choose.prio) {
   if (this.task.choose) {
      this.list = this.task.choose;
   }
   else {
     this.list = {
       prio: [],
       noPrio: []
     }
   }

      console.log("this is the list", this.list);
      console.log("this is the list", this.task.chooses);
    // }
    // else {

      let i;
      for(i in this.task.chooses) {
        if (this.task.chooses[i].closed === 'yes' || this.task.chooses[i].closed === 'hidden') {
         // this.list.noPrio.push({id:this.task.chooses[i].id, title:this.task.chooses[i].title, closed:this.task.chooses[i].closed})
        }
        else {
          // if not in array
          let a;
          let existing = false;
          for(a in this.list.prio) {
            if (this.list.prio[a].id === this.task.chooses[i].id) {
              existing = true;
            }
          }
          let b;
          for(b in this.list.noPrio) {
            if (this.list.noPrio[b].id === this.task.chooses[i].id) {
              existing = true;
            }
          }
          if (!existing) {
            this.list.noPrio.push({id:this.task.chooses[i].id, title:this.task.chooses[i].title, closed:this.task.chooses[i].closed});
          }
        }
      }

      // check duplicates
      let existingIDs = {};
      let c;
      for(c in this.list.prio) {
        if (existingIDs[this.list.prio[c].id]) {
          this.list.prio.splice(c, 1);
        }
        else {
          existingIDs[this.list.prio[c].id] = true;
        }
      }
      let d;
      for(d in this.list.noPrio) {
        if (existingIDs[this.list.noPrio[d].id]) {
          this.list.noPrio.splice(d, 1);
        }
        else {
          existingIDs[this.list.noPrio[d].id] = true;
        }
      }







      // this.list.noPrio = [{id:5, title:'Keine Teilnahme möglich'}];
    // }


  },

}
</script>
<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.chooseItem ul {
  margin-left: 0;
  padding-left: 20px;
}
.item-choose {
  padding: 12px;
  margin-bottom:12px;
  background-color: #ebf0f6;
}
.item-choose p{
  margin-bottom: 0;
  font-weight: normal;
  font-size: 14px;
}
@media (min-width: 1024px){
  .item-choose{
    padding: 24px;
    margin-bottom: 24px;
  }
}
</style>
